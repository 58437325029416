/* Chrome, Safari, Edge, Opera */
.al-statistic-table input::-webkit-outer-spin-button,
.al-statistic-table input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.al-statistic-table input[type=number] {
    -moz-appearance: textfield;
}

.al-statistic-table {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.al-statistic-table h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.al-statistic-table .description {
    margin-left: 10px;
    font-style: italic;
}

.al-statistic-table .table-info {
    display: flex;
    align-items: center;
}

.al-statistic-table .legend {
    position: absolute;
    top: 30px;
    right: calc(100% + 15px);
    display: flex;
    font-size: 16px;
}

.al-statistic-table .legend_item {
    height: 32px;
    width: max-content;
    padding: 5px;
    margin: 0 5px;
    border-radius: 3px;
}

.al-statistic-table .table-wrapper {
    position: relative;
}

.al-statistic-table .loading {
    margin-top: 10px;
    font-weight: bold;
}

.al-statistic-table .checkboxes {
    display: flex;
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #A4BAB7;
    border-radius: 3px;
}

.al-statistic-table .checkbox:first-child {
    margin-right: 10px;
}

.al-statistic-table .checkbox .checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.al-statistic-table .checkbox .checkbox-input {
    display: none;
}

.al-statistic-table .checkbox .checkbox-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding: 3px;
    margin-right: 5px;
    border-radius: 3px;
    border: 1px solid grey;
}

.al-statistic-table .checkbox .checkbox-icon:before {
    content: "";
    display: block;
    min-width: 100%;
    min-height: 100%;
    border-radius: 2px;
    background-color: transparent;
    transition: background-color .3s ease-in-out;
}

.al-statistic-table .checkbox .checkbox-text {
    font-size: 17px;
    user-select: none;
}

.al-statistic-table .checkbox .checkbox-input:checked ~ .checkbox-icon:before {
    background-color: #A4BAB7;
}

.al-statistic-table .table-header {
    text-align: left;
}

.al-statistic-table th {
    text-align: center;
    font-weight: bold;
    position: relative;
}

.al-statistic-table td {
    text-align: center;
}

.al-statistic-table td a {
    color: #6E85B7;
}

.al-statistic-table .call-count,
.al-statistic-table .match-count {
    text-align: center;
    border-bottom: 0;
}

.al-statistic-table .call-count {
    background-color: #A4BAB7;
    cursor: pointer;
    user-select: none;
}

.al-statistic-table .match-count {
    background-color: #EFF2C0;
    cursor: pointer;
    user-select: none;
}

.al-statistic-table .custom-range input {
    width: 50px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid grey;
    outline: 0;
    text-align: center;
    transition: all .3s ease-in-out;
}

.al-statistic-table .custom-range input:focus {
    border: 1px solid grey;
    box-shadow: 2px 2px 5px 0 grey;
}

.al-statistic-table .custom-range {
    position: relative;
}

.al-statistic-table .actions {
    margin-left: 20px;
}

.al-statistic-table .action_load {
    height: 32px;
    padding: 5px;
    margin: 0 5px;
    border-radius: 3px;
    background-color: #EFF2C0;
    cursor: pointer;
    user-select: none;
    border: none;
    font-size: 16px;
    opacity: .8;
    transition: opacity .3s ease-in-out;
}

.al-statistic-table .action_load:hover {
    opacity: 1;
}

.al-statistic-table .action_load.disabled {
    opacity: .6;
}
